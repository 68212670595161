
.menu-item {
    display: block;
    padding: 5px 10px;
    transition: 0.1s;
    color: #666;
}

.menu-item:hover {
    background-color: #eee;
    cursor: pointer;
}

.close-menu-item {
    display: block;
    margin-top: 10px;
    padding-top: 5px;
    padding-left: 10px;
    padding-bottom: 10px;
    border-top: 1px solid #666;
    transition: 0.1s;
    color: #666;
}

.close-menu-item:hover {
    background-color: #eee;
    cursor: pointer;
}