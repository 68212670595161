.gm-style {
    font: 600 15px Roboto, Arial, sans-serif !important;
}
.segment-measure-text {
    stroke: none !important;
    text-shadow: 2px 2px 10px #ffffff !important;
    color: white !important;
}
.node-measure-text {
    stroke: none !important;
    text-shadow:  2px 2px 10px #ffffff !important;
    color: white !important;
}