@font-face {
  font-family: "neutra-light";
  src: url("assets/fonts/neutra-text-light.otf") format("otf"),
  url("assets/fonts/neutra-text-light.otf") format("otf");
}
@font-face {
  font-family: "neutra-bold";
  src: url("assets/fonts/neutra-text-bold.otf") format("otf"),
  url("assets/fonts/neutra-text-bold.otf") format("otf");
}
@font-face {
  font-family: "neutra-alt";
  src: url("assets/fonts/neutra-text-alt.otf") format("otf"),
  url("assets/fonts/neutra-text-alt.otf") format("otf");
}
*{
  font-family: neutra-alt, sans-serif;
}
body {
  margin: 0;
  font-family: neutra-alt, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:neutra-alt, sans-serif;
}
